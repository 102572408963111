import { EyeIcon, PlusIcon } from "@heroicons/react/24/outline";
import mime from "mime";
import { useContext, useEffect, useState, type FC } from "react";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { TfiTrash } from "react-icons/tfi";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Modal,
  Pagination as RSPagination,
  SelectPicker,
} from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { Activity, ActivityReq } from "../model/activity";
import { LoadingContext } from "../objects/loading_context";
import { Pagination } from "../objects/pagination";
import {
  createActivity,
  deleteActivity,
  getEmployees,
  getMyActivities,
} from "../repositories/activity";
import { TASK_STATUS } from "../utils/constant";
import { confirmDelete, nl2br } from "../utils/helper";
import { errorToast } from "../utils/helper_ui";
import MapComponent from "./map";
import { Employee } from "../model/employee";
import moment from "moment";

interface MyAssignmentProps {}

const MyAssignment: FC<MyAssignmentProps> = ({}) => {
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [modalAddOpen, setModalAddOpen] = useState(false);

  const [status, setStatus] = useState<string | null>(null);

  const [assignments, setAssignments] = useState<Activity[]>([]);

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [date, setDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    getAllActivities("assignment");
  }, [mounted, page, limit, search, activityType, dateRange]);

  const getAllActivities = async (filter: string) => {
    if (!mounted) return;
    try {
      setIsLoading(true);
      let resp = await getMyActivities(
        { page, limit, search },
        { dateRange, activityType, filter: filter }
      );
      const respJson = await resp.json();
      setAssignments(respJson.data);
      setPagination(respJson.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-1 bg-gray-50 overflow-y-auto relative h-full">
      <div className="my-2 px-2" >
        <Button
          appearance="ghost"
          block
          color="violet"
          onClick={() => setModalAddOpen(true)}
        >
          + Penugasan
        </Button>
      </div>
      {assignments.map((e) => (
        <div
          key={e.id}
          className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0"
        >
          <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
            <Moment format="DD">{e.start_date}</Moment>
            <Moment
              format="MMM YYYY"
              className="text-[8pt] text-center text-red-400"
            >
              {e.start_date}
            </Moment>
          </div>
          <div className="flex  flex-1 flex-row justify-between">
            <div className="py-2 cursor-pointer">
              <div className="flex flex-col">
                <div>
                  {e.activity_type == "TO_DO" && <></>}
                  {e.activity_type == "VISIT" && <></>}
                </div>
                <div>
                  {e.status == "DRAFT" && (
                    <Badge
                      color="cyan"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                  {e.status == "IN_PROGRESS" && (
                    <Badge
                      color="blue"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                  {e.status == "FINISHED" && (
                    <Badge
                      color="green"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                  {e.status == "CANCELED" && (
                    <Badge
                      color="red"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                </div>
                <div></div>
                <p className="font-[700] text-base">{e.name}</p>

                <p
                  className="text-xs"
                  dangerouslySetInnerHTML={{ __html: nl2br(e.description) }}
                ></p>
                {e.remarks && (
                  <>
                    <p className="text-sm font-semibold mt-4">Catatan</p>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={e.remarks}
                      className={"text-sm"}
                    />
                  </>
                )}
                <p className=" text-sm">
                  Ditugaskan Ke:{" "}
                  <span className="font-[700]">{e.assigned_to_name}</span>{" "}
                </p>
                <div className="flex flex-row mt-4">
                  {e.files.map((att) => (
                    <Link key={att.url} to={att.url} target="_blank">
                      {mime.getType(att.url)?.includes("image") ? (
                        <Avatar size="sm" circle src={att.url} />
                      ) : (
                        <IoDocumentAttachOutline
                          size={32}
                          className="text-gray-400"
                        />
                      )}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="py-4 flex flex-col items-center w-12 ">
              {e.activity_type == "TO_DO" && (
                <EyeIcon
                  className="text-blue-400 w-5 mb-2 cursor-pointer"
                  onClick={() => {
                    if (e.activity_type == "TO_DO") {
                      nav(`/task/${e.id}`);
                    }
                  }}
                />
              )}
              {e.status != "FINISHED" && (
                <TfiTrash
                  className="text-red-300"
                  size={18}
                  onClick={() => {
                    confirmDelete(() => {
                      setIsLoading(true);
                      deleteActivity(e.id)
                        .then(() => {
                            getAllActivities("assignment");
                        })
                        .catch((err) => errorToast(`${err}`))
                        .finally(() => setIsLoading(false));
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ))}

      {(pagination?.total_pages ?? 0) > 1 && (
        <div className="bg-white flex justify-center p-4">
          <RSPagination
            total={pagination?.total_records ?? 0}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
          />
        </div>
      )}
      <Modal
        size={"xs"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Penugasan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col">
            <div className="mb-4">
              <p className="text-sm font-[700]">Tgl</p>
              <DatePicker
                block
                value={date}
                onChange={(val) => setDate(val!)}
                format="dd MMM yyyy"
              />
            </div>
            <div className="mb-4">
              <p className="text-sm font-[700]">Tgl Selesai</p>
              <DatePicker
                block
                value={endDate}
                onChange={(val) => setEndDate(val!)}
                format="dd MMM yyyy"
              />
            </div>
            <div className="mb-4">
              <p className="font-[700]">Nama Task</p>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(val) => setName(val.target.value)}
              />
            </div>
            <div className="mb-4">
              <p className="font-[700]">Keterangan</p>
              <textarea
                rows={5}
                className="form-control"
                value={description}
                onChange={(val) => setDescription(val.target.value)}
              />
            </div>
            <div className="mb-4">
              <p className="font-[700]">Ditugaskan Kepada</p>
              <SelectPicker
                block
                data={employees}
                labelKey="full_name"
                valueKey="id"
                onSelect={(val) => {
                  setEmployeeId(val);
                }}
                onSearch={(val) =>
                {
                  if (val) {
                    getEmployees({ page: 1, limit: 5, search: val })
                    .then((v) => v.json())
                    .then((v) => setEmployees(v.data))
                  }
                }
                }
              />
            </div>
            
            {/* <div className="mb-4">
              <p className="text-sm font-[700]">Jam</p>
              <DatePicker
                block
                value={startTime}
                format="HH:mm"
                caretAs={FaClock}
                placement="bottomStart"
                onChange={(val) => {
                  setStartTime(val);
                }}
              />
              <p className="text-sm font-[700]">s/d</p>
              <DatePicker
                block
                value={endTime}
                format="HH:mm"
                caretAs={FaClock}
                placement="bottomStart"
                onChange={(val) => {
                  setEndTime(val);
                }}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalAddOpen(false);
            }}
          >
            Batal
          </Button>
          <Button
            appearance="primary"
            onClick={() => {
              let req: ActivityReq = {
                activity_type: "TO_DO",
                name,
                description,
                start_date: date.toISOString(),
                files: [],
                status: "DRAFT",
                assigned_to_id: employeeId,
              };
              if (endDate) {
                req.end_date = endDate.toISOString();
              }
              setIsLoading(true);
              createActivity(req)
                .then(() => {
                    getAllActivities("assignment");
                  setModalAddOpen(false);
                })

                .finally(() => setIsLoading(false))
                .catch((err) => {
                  errorToast(err);
                });
            }}
          >
            Tambah
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default MyAssignment;
