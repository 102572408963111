import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { BsPlus } from "react-icons/bs";
import {
  Badge,
  Button,
  DatePicker,
  Modal,
  Pagination as RSPagination,
  SelectPicker,
} from "rsuite";
import { FaClock } from "react-icons/fa6";
import moment from "moment";
import { LoadingContext } from "../objects/loading_context";
import { addOvertime, getOvertimes } from "../repositories/overtime";
import { errorToast } from "../utils/helper_ui";
import { Pagination } from "../objects/pagination";
import { Overtime } from "../model/overtime";
import Moment from "react-moment";
import nav from "../components/nav";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { todayAttendance } from "../repositories/attendance";
import { Attendance } from "../model/attendance";
import { getEmployees } from "../repositories/activity";
import { Employee } from "../model/employee";

interface OvertimePageProps {}

const OvertimePage: FC<OvertimePageProps> = ({}) => {
  const [mounted, setMounted] = useState(false);
  const [startTime, setStartTime] = useState<Date>(moment().toDate());
  const [endTime, setEndTime] = useState<Date>(moment().toDate());
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [reason, setReason] = useState("");
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [overtimes, setOvertimes] = useState<Overtime[]>([]);
  const [attendance, setAttendance] = useState<Attendance | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllOvertimes();
    todayAttendance()
      .then((v) => v.json())
      .then((v) => {
        if (v.data.id) {
          setAttendance(v.data);
        }
      });
  }, [page, limit, search, mounted]);

  const getAllOvertimes = () => {
    getOvertimes({ page, limit, search })
      .then((v) => v.json())
      .then((v) => {
        setOvertimes(v.data);
        setPagination(v.pagination);
      });
  };
  return (
    <MainLayout hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Lembur</h3>
          <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto ">
          {overtimes.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 cursor-pointer"
              onClick={() => nav(`/overtime/${e.id}`)}
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.date_requested}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.date_requested}
                </Moment>
              </div>
              <div className="flex flex-col flex-1">
                <div className="font-bold  ">{e.reason}</div>
                <div>
                  {e.status == "PENDING" && (
                    <Badge
                      color="cyan"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "REVIEWED" && (
                    <Badge
                      color="green"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "APPROVED" && (
                    <Badge
                      color="blue"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "REJECTED" && (
                    <Badge color="red" className="text-xs" content={e.status} />
                  )}
                </div>
                <div className="flex  flex-1 flex-row justify-between">
                  <div className="py-2">
                    <p className="text-sm font-[700]">Mulai</p>
                    <Moment format="HH:mm">{e.start_time_request}</Moment>
                  </div>
                  <div className="py-2">
                    <p className="text-sm font-[700]">Selesai</p>
                    <Moment format="HH:mm">{e.end_time_request}</Moment>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {overtimes.length == 0 && (
            <div className="flex justify-center flex-col py-32 h-full px-4">
              <p className="text-center">Belum ada data lembur</p>
              <Button
                onClick={() => {
                  if (!attendance) {
                    errorToast("Anda belum clock ini hari ini");
                    return;
                  }
                  setModalAddOpen(true);
                }}
                className="w-full"
                color="blue"
                appearance="primary"
              >
                Ajukan Lembur
              </Button>
            </div>
          )}

          {(pagination?.total_pages ?? 0) > 1 && (
            <div className="bg-white flex justify-center p-4">
              <RSPagination
                total={pagination?.total_records ?? 0}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        size={"xs"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Ajukan Lembur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="text-sm font-[700]">Mulai</p>
            <DatePicker
              value={startTime}
              block
              format="dd-MM-yyyy HH:mm"
              caretAs={FaClock}
              placement="bottomStart"
              onChange={(val) => {
                setStartTime(val!);
              }}
            />
            <p className="text-sm font-[700]">s/d</p>
            <DatePicker
              value={endTime}
              block
              format="dd-MM-yyyy HH:mm"
              caretAs={FaClock}
              placement="bottomStart"
              onChange={(val) => {
                setEndTime(val!);
              }}
            />
          </div>
          <div className="mb-4">
              <p className="font-[700]">Diajukan Kepada</p>
              <SelectPicker
                block
                data={employees}
                labelKey="full_name"
                valueKey="id"
                onSelect={(val) => {
                  setEmployeeId(val);
                }}
                onClose={() => setEmployeeId(null)}
                value={employeeId}
                onSearch={(val) =>
                {
                  if (val) {
                    getEmployees({ page: 1, limit: 5, search: val })
                    .then((v) => v.json())
                    .then((v) => setEmployees(v.data))
                  }
                }
                }
              />
            </div>
          <div className="mb-4">
            <p className="text-sm font-[700]">Keterangan</p>
            <textarea
              className="form-control"
              value={reason}
              rows={5}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Keterangan"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            onClick={async () => {
              try {
                if (!attendance) {
                  errorToast("Anda belum clock ini hari ini");
                  return;
                }
                setIsLoading(true);
                await addOvertime({
                  reason,
                  start_time_request: startTime.toISOString(),
                  end_time_request: endTime.toISOString(),
                  attendance_id: attendance?.id!,
                  employee_approver_id: employeeId,
                });
                setModalAddOpen(false);
                getAllOvertimes();
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default OvertimePage;
